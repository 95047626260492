
const getPosition = function (options) {
    return new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
}


const Component = async () => {

    return new Promise(async (resolve) => {
        if (navigator.geolocation) {
            let results = {};
            const { latitude, longitude } = await getPosition().then((x) => {
                return x.coords;
            }).catch((err) => {
                console.error(err.message);
                return { latitude: null, longitude: null };
            });

            if (latitude && longitude) {
                const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
                const res = await fetch(url);
                const json = await res.json();
                const addr = json.address;
                let address = [addr.road, addr.street, addr.suburb].filter(Boolean).join(', ');
                results = {
                    latitude, longitude, ...results, address,
                    city: addr.county, state: addr.state, postcode: addr.postcode, country: addr.country
                };
            }
            return resolve(results);
        } else {
            console.error('Geolocation is not supported by this browser.');
            return resolve({});
        }
    });
};

export default Component;

