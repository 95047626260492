import { React, Link, GetGPSLocation } from "shared/common";
import { useDispatch, useSelector } from "react-redux";
import { setApplyState } from "features/applyNow/applySlice";
import { TextInput } from "components";
import Helper from "shared/helper";

const Component = () => {

    const dispatch = useDispatch();
    const [initialize, setInitialize] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const { state, address, city } = useSelector(x => x.applyNowReducer);

    const OnStageMoved = (e, num) => {
        if (e) e.preventDefault();
        dispatch(setApplyState({ stage: num }))
    }

    const OnInputChange = (e) => {
        const { name, value } = e;
        dispatch(setApplyState({ [name]: value }));
    };

    const OnStateChanged = (e) => {
        const { name, requiredMessage, clear } = e;
        let tmp = errors.filter((x) => x.field !== name) || [];
        if (!clear) tmp.push({ field: name, value: requiredMessage });
        setErrors(tmp);
    }

    const OnSubmitClicked = (e) => {
        e.preventDefault();

        setErrors([]);

        const { errors, valid } = Helper.IsFormValid('formStage1');

        if (!valid) {
            setErrors(errors);
            return;
        }

        dispatch(setApplyState({ stage: 3 }))

    }

    const LoadGeoLocation = async () => {
        if (Helper.IsNullValue(address)) {
            global.Busy(true);
            let rslt = await GetGPSLocation();
            if (!Helper.IsJSONEmpty(rslt)) {
                dispatch(setApplyState({ 'address': rslt.address, 'city': rslt.city, 'state': rslt.state }));
            }
            global.Busy(false);
        }
    }

    if (initialize) {
        setInitialize(false);
        LoadGeoLocation();
    }

    React.useEffect(() => { setInitialize(true); }, []);

    return (
        <div>
            <div className='quoteContentBlk'>
                <div className='stageHead'>
                    Great! Please tell us the location where you want to buy from
                    <form id="formStage1" className="form" onSubmit={OnSubmitClicked}>
                        <div className="form-group">

                            <TextInput
                                id="address"
                                name="address"
                                placeholder={''}
                                value={Helper.ToString(address)}
                                onInputChanged={(e) => OnInputChange(e)}
                                className={`form-control ${address && 'has-value'} address-field`}
                                errors={errors}
                                required={true}
                                requiredMessage={'Address is required'}
                                onStateChanged={OnStateChanged}
                            />

                            <label className={`form-label ${address && 'shrink'}`}>Address</label>
                        </div>

                        <ul className='quoteIIcol'>
                            <li>
                                <div className="form-group">

                                    <TextInput
                                        id="city"
                                        name="city"
                                        placeholder={''}
                                        value={Helper.ToString(city)}
                                        onInputChanged={(e) => OnInputChange(e)}
                                        className={`form-control ${city && 'has-value'} city-field`}
                                        errors={errors}
                                        required={true}
                                        requiredMessage={'City is required'}
                                        onStateChanged={OnStateChanged}
                                    />

                                    <label className={`form-label ${city && 'shrink'}`}>City</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">

                                    <TextInput
                                        id="state"
                                        name="state"
                                        placeholder={''}
                                        value={Helper.ToString(state)}
                                        onInputChanged={(e) => OnInputChange(e)}
                                        className={`form-control ${state && 'has-value'} state-field`}
                                        errors={errors}
                                        required={true}
                                        requiredMessage={'State is required'}
                                        onStateChanged={OnStateChanged}
                                    />

                                    <label className={`form-label ${state && 'shrink'}`}>State</label>
                                </div>
                            </li>
                        </ul>
                        <div className='formButtonLabel'>
                            <Link to="#" onClick={(e) => OnStageMoved(e, 1)}><div className='prevButton'></div></Link>
                            <button type="submit" className="submit-button">Next</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
};

export default Component;
