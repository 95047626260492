import React from 'react';

const Tooltip = ({ text, style }) => {
    return (
        <div className="tooltip" style={style}>
            {text}
        </div>
    );
};

export default Tooltip;