import { createSlice } from '@reduxjs/toolkit';

import Helper from "shared/helper";

/* const initState = {
    downPaymentValid: true,
    interestRate: "9.99%",
    tenure: 12,
    stampDutyCharges: 999,
    specialDiscount: 0,
    loanAmtBeforeDiscount: 0,
    loanAmtAfterDiscount: 0,
    processingFee: 0,
    totalPayable: 0,
    displayEmiAmount: 0,
    brandName: "TVS Motors",
    onRoadPrice: 100000,
    downPayment: 20000,
    mobileOTP: 1234,
    mobileNumber: 9845098450,
}; */

const MinLoanAmount = 10000;

const DiscountBenifits = [
    { min: 9999, max: 19999, value: 1000 },
    { min: 20000, max: 49999, value: 2000 },
    { min: 50000, max: 10000000, value: 5000 }
];

const initState = {
    interestRate: "9.99%",
    tenure: 12,
    stampDutyCharges: 0,
    specialDiscount: 0,
    loanAmtBeforeDiscount: 0,
    loanAmtAfterDiscount: 0,
    processingFee: 0,
    totalPayable: 0,
    displayEmiAmount: 0,
    isOTPValid: false,
    otpSent: false
};

const calculateEMI = (tenure, rate1, amount) => {
    const principle = parseFloat(amount);
    const rate = parseFloat(rate1) / 1200;
    const time = parseFloat(tenure);
    const emiAmount = Math.round((principle / time) + (principle * rate));
    const totalPayment = emiAmount * time;
    const totalInterest = totalPayment - principle;
    return { emiAmount, totalPayment, totalInterest };
};

const Validate = (state) => {

    if (!Helper.IsNullValue(state?.onRoadPrice) && !Helper.IsNullValue(state?.downPayment)) {
        let noLoanApproved = false;
        let showSpecialDiscount = null;
        const stampDutyCharges = 999;
        let newState = {
            ...state, stampDutyCharges, downPaymentInValid: false, specialDiscount: 0, beforeDiscount: 0,
            validInput: false, noLoanApproved: false, showSpecialDiscount: null
        };
        if (state.downPayment > state.onRoadPrice || state.downPayment > state.onRoadPrice * 0.95) {
            newState = { ...state, downPaymentInValid: true, validInput: false };
        } else {

            const _amount = state.onRoadPrice - state.downPayment;
            const loanAmtBeforeDiscount = _amount + stampDutyCharges;
            let specialDiscount = 0, loanAmtAfterDiscount = 0, totalPaymentBeforeDiscount = 0, totalPaymentAfterDiscount = 0;
            let emiAmount = 0, displayEmiAmount = 0;

            noLoanApproved = loanAmtBeforeDiscount < MinLoanAmount;

            if (noLoanApproved) {
                return {
                    ...newState, specialDiscount, loanAmtBeforeDiscount, loanAmtAfterDiscount, validInput: !noLoanApproved,
                    totalPaymentBeforeDiscount, totalPaymentAfterDiscount, emiAmount, displayEmiAmount, noLoanApproved, showSpecialDiscount
                };
            }
            const { min, max, value } = DiscountBenifits.find(x => loanAmtBeforeDiscount >= x.min && loanAmtBeforeDiscount <= x.max) || { max: 0, value: 0 };
            specialDiscount = value || 0;
            if (specialDiscount > 0 && min < 50000) {
                showSpecialDiscount = `You are eligible for GaadiPlus Benefit of ${specialDiscount} as the Loan Amount is less than ${max + 1}`;
            }


            loanAmtAfterDiscount = loanAmtBeforeDiscount + stampDutyCharges - specialDiscount;

            totalPaymentBeforeDiscount = calculateEMI(state.tenure, state.interestRate, loanAmtBeforeDiscount).totalPayment;
            totalPaymentAfterDiscount = totalPaymentBeforeDiscount - specialDiscount;

            emiAmount = calculateEMI(state.tenure, state.interestRate, loanAmtBeforeDiscount).emiAmount;
            displayEmiAmount = totalPaymentAfterDiscount / state.tenure;

            newState = {
                ...newState, specialDiscount, loanAmtBeforeDiscount, loanAmtAfterDiscount, validInput: !noLoanApproved,
                totalPaymentBeforeDiscount, totalPaymentAfterDiscount, emiAmount, displayEmiAmount, noLoanApproved, showSpecialDiscount
            };
        }

        return newState;
    } else if (Helper.IsNullValue(state?.onRoadPrice) && Helper.IsNullValue(state?.downPayment) && Helper.ToBool(state?.isOTPValid)) {
        return { ...initState, isOTPValid: true, onRoadPrice: state?.onRoadPrice };
    } else {
        return {
            ...initState, ...state, validInput: false, noLoanApproved: false, showSpecialDiscount: null
        };
    }
}

export const objectStateSlice = createSlice({
    name: 'objectState',
    initialState: initState,
    reducers: {
        setObjectState: (state, action) => {
            const newState = { ...state, ...action.payload };
            return Validate(newState);
        },
        clearObjectState: () => {
            return initState;
        }
    }
});

const { setObjectState, clearObjectState } = objectStateSlice.actions;
export { setObjectState, clearObjectState };
export default objectStateSlice.reducer;