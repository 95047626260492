import { React, Link } from "shared/common";
import RequestCallbackForm from 'screens/component/RequestCallbackForm';
import SeeHowPop from '../../component/seeHowPop';
import LoanQuotePop from 'screens/pop/loanQuotePop';

const Component = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpen1, setIsOpen1] = React.useState(false);
    const [isOpen2, setIsOpen2] = React.useState(false);

    const togglePopup = (e) => {
        if (e) e.preventDefault();
        setIsOpen(!isOpen);
    };
    const togglePopup1 = () => {
        setIsOpen1(!isOpen1);
    };
    const togglePopup2 = () => {
        setIsOpen2(!isOpen2);
    };

    return (
        <>
            <div id="contentGraphicBlk">
                <ul className="txtGraphicBlk widthfix">
                    <li className="txtGraphicBlk">
                        <div className="txtLine1">
                            Revolutionizing Bike Dreams: Making Your Bike Ride Affordable!
                        </div>
                        <div className="graphicSmallScreen"></div>
                        <div className="txtLine2">
                            Buy any <span className="txtLine2Bold">Bike</span> from any <span className="txtLine2Bold">Dealer</span>.
                        </div>
                        <div className="txtLine2">Enjoy a discount of <span className="txtLine2BoldOrangeBg">Rs. 5000/-</span> on top of your best-negotiated dealership price.</div>
                        <ul className="btnBlk">
                            <li className="btnBlk">
                                <input type="button" className="contact_button" value="See How" onClick={togglePopup1} />
                            </li>
                            {/* <li className="btnBlk">
                                <Link to="/approval"><input type="button" className="contact_button" value="Apply Loan" /></Link>
                            </li> */}
                            <li className="btnBlk">
                                <Link to="/apply"><input type="button" className="contact_button" value="Apply Now" /></Link>
                            </li>
                            <li className="btnBlk">
                                <input type="button" className="contact_button" value="Quotation" onClick={togglePopup2} />
                            </li>
                        </ul>
                    </li>
                    <li className="txtGraphicBlk"></li>
                </ul>
            </div>
            <RequestCallbackForm formId={'frmContentCallback'} isOpen={isOpen} onClose={togglePopup} />

            <SeeHowPop isOpen={isOpen1} onClose={togglePopup1} />
            <LoanQuotePop showLogo={true} isOpen={isOpen2} onClose={togglePopup2} />
        </>
    )
}

export default Component;