import { createSlice } from '@reduxjs/toolkit';
import Helper from "shared/helper";

const initState = { stage: 1 };

const CombileVehicleNames = (state) => {
    let names = [];
    ['brandName', 'modelName', 'variantName'].forEach(x => {
        if (!Helper.IsNullValue(state[x])) names.push(state[x].trim());
    });

    return { ...state, vehicleFullName: names.join(" ") };
}

export const applyNowSlice = createSlice({
    name: 'applyNow',
    initialState: initState,
    reducers: {
        setApplyState: (state, action) => {
            let _state = { ...state, ...action.payload };
            return CombileVehicleNames(_state);
        },
        clearApplyState: () => {
            return initState;
        }
    }
});

export const { setApplyState, clearApplyState } = applyNowSlice.actions;

export default applyNowSlice.reducer;