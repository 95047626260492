import React from "react";
import Helper from "shared/helper";

const Component = (props) => {
    const { id, name, value, placeholder, errors, required, requiredMessage, location,
        onInputChanged, onStateChanged } = props;

    const inputProps = {
        id, name, placeholder, autoComplete: Helper.TimeStamp(),
        type: "text", value: Helper.ToString(value)
    };

    const OnInputChanged = (e) => {
        const { name, value } = e.target;
        onInputChanged && onInputChanged({ name, value });
        OnBlurChanged(e);
    };

    const OnBlurChanged = (e) => {
        const { name, value } = e.target;
        let clear = false;

        if (required && Helper.IsNullValue(value)) {
            onStateChanged && onStateChanged({ name, requiredMessage, clear });
        }

        if (!Helper.IsNullValue(value)) {
            let formatVal = Helper.IsEmailValid(value);
            clear = false;
            if (!formatVal) {
                onStateChanged && onStateChanged({ name, requiredMessage, clear });
            } else {
                clear = true;
                onStateChanged && onStateChanged({ name, requiredMessage, clear });
            }
        }

    };

    return (
        <input {...inputProps}
            autoComplete={"off"}
            onChange={OnInputChanged}
            onBlur={OnBlurChanged}
            data-req={required}
            data-req-msg={requiredMessage}
            data-location={location}
            style={{
                borderColor: Helper.GetErrorBackColor(errors, id)
            }}
        />
    );
};

export default Component;
