import { React } from "shared/common";
import Container from "screens/container";
import { BikeSlider, ContentGraphic, BottomContact } from "./childs";

const Component = () => {
  return (
    <>
      <Container headerType={'HeaderHome'} footerType={'FooterHome'}>

        {/*Bike Slider*/}
        <BikeSlider />

        {/*Curve Background and Graphic*/}
        <ContentGraphic />

        {/*Homepage Bottom Contact*/}
        <BottomContact />

      </Container>
    </>
  );
};

export default Component;
