import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { clearObjectState } from "features/objectState/objectStateSlice";
import Helper from "shared/helper";

const EMIChart = () => {

    const row = useSelector(state => state.objectStateReducer) || {};
    const dispatch = useDispatch();

    // Recalculate installmentAmount and discountAmount whenever tenure changes
    const installmentAmount = row.totalPaymentBeforeDiscount / row.tenure;
    const discountAmount = row.specialDiscount / row.tenure;

    const installments = Array.from({ length: row.tenure }, (_, index) => index + 1);

    let totalAmount = 0;
    let totalDiscount = 0;
    let totalFinal = 0;

    React.useEffect(() => {
        if (sessionStorage.getItem('loadedCount') === null) {
            sessionStorage.setItem('loadedCount', 1);
        } else {
            dispatch(clearObjectState());
        }
    }, [dispatch]);

    return (
        <div>
            <div className='emiChartHead'>EMI Chart</div>
            <div className='emiTable'>
                <table border="0" cellPadding="10">
                    <thead>
                        <tr>
                            <th>Installment</th>
                            <th>EMI Before Benefit</th>
                            <th>GaadiPlus Benefit</th>
                            <th>EMI After Benefit</th>
                        </tr>
                    </thead>
                    {row.isOTPValid && (
                        <>
                            <tbody>
                                {installments.map((installment) => {
                                    const finalAmount = installmentAmount - discountAmount;
                                    totalAmount += installmentAmount;
                                    totalDiscount += discountAmount;
                                    totalFinal += finalAmount;
                                    return (
                                        <tr key={installment}>
                                            <td>{installment}</td>
                                            <td>{Helper.INRCurrencyFormat(installmentAmount || 0, true)}</td>
                                            {/* <td>{installmentAmount.toFixed(2)}</td> */}
                                            <td>{Helper.INRCurrencyFormat(discountAmount || 0, true)}</td>
                                            <td>{Helper.INRCurrencyFormat(finalAmount || 0, true)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="1">&nbsp;</td>
                                    <td>{Helper.INRCurrencyFormat(totalAmount || 0, true)}</td>
                                    <td>{Helper.INRCurrencyFormat(totalDiscount || 0, true)}</td>
                                    <td>{Helper.INRCurrencyFormat(totalFinal || 0, true)}</td>
                                </tr>
                            </tfoot>
                        </>
                    )}

                </table>
            </div>

        </div>
    );
};

export default EMIChart;
