import { React, Link, useLocation } from "shared/common";
import RightNavi from "./component/rigntNavi";
import HeaderResponsive from "./component/headerResponsive";
import RequestCallbackForm from 'screens/component/RequestCallbackForm';

const HeaderHome = (props) => {

  const [isOpen, setIsOpen] = React.useState(false);

  const togglePopup = (e) => {
    if (e) e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header className="header">
        <div className="widthfix">
          <div className="logo">
            <Link to="/">
              <div className="gaadiplusLogo logoPositionHome"></div>
            </Link>
          </div>
          <div className="headerTopRight">
            <RightNavi {...props} togglePopup={togglePopup} />
          </div>

        </div>
      </header>

      <HeaderResponsive {...props} togglePopup={togglePopup} />

      <RequestCallbackForm formId={'frmHeaderCallback'} isOpen={isOpen} onClose={togglePopup} />
    </>
  );
};

const HeaderInPage = (props) => {

  const [isOpen, setIsOpen] = React.useState(false);

  const togglePopup = (e) => {
    if (e) e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header className="headerInpage">
        <div className="widthfix">
          <div className="logo">
            <Link to="/">
              <div className="gaadiplusLogo logoPosInpage"></div>
            </Link>
          </div>

          <div className="headerTopRightInpage">
            <RightNavi togglePopup={togglePopup} />
          </div>
        </div>
      </header>

      <HeaderResponsive {...props} togglePopup={togglePopup} />

      <RequestCallbackForm formId={'frmHeaderCallback'} isOpen={isOpen} onClose={togglePopup} />
    </>
  );
}

const HeaderApproval = (props) => {

  return (
    <>
      <header className="headerInpage">
        <div className="widthfix">
          <div className="logo">
            <Link to="/">
              <div className="gaadiplusLogo logoPosInpage"></div>
            </Link>
          </div>

          <div className="headerTopRightInpage">
            Call us at: 12345
          </div>
        </div>
      </header>
    </>
  );
}

const Component = (props) => {
  const loc = useLocation().pathname;
  const { type } = props;

  if (type === 'approval') {
    return <HeaderApproval loc={loc} {...props} />;
  } else if (type === 'inner') {
    return <HeaderInPage loc={loc} {...props} />;
  } else {
    return <HeaderHome loc={loc} {...props} />;
  }
};

export default Component;
