import { React, Link } from "shared/common";
import Container from "screens/container";


const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container">
                    <div id="content">
                        <div className="BC_blk">
                            <div className="widthfix">
                                <div className="BC_txt">Reach Us</div>
                                <div className="BC_descTxt">Connect. Communicate. Collaborate.</div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className="reachUsBlk">
                                <div className="txtNormal mar_15">
                                    Have questions, feedback, or simply want to get in touch? <br />We're here to listen! Reach out to us through the following channels, and our team will be happy to assist you:
                                </div>
                                <div className="txtNormal mar_10">
                                    <b>Email:</b> contact@gaadiplus.com
                                </div>
                                <div className="txtNormal mar_10">
                                    <b>Phone:</b> 080 26496555 / 080 44634304
                                </div>
                                <div className="txtNormal mar_20">
                                    <b>Social media:</b>  Connect with us on <Link>Facebook</Link> and <Link>Instagram</Link> for updates, news, and more.
                                </div>
                                <div className="txtNormal">
                                    Stay tuned for exciting updates and new features coming soon to GaadiPlus!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;