import React from "react";
import Helper from "shared/helper";

const DigitCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
const NumCodes = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
const ControlKeyes = [8, 9, 13, 17, 35, 36, 37, 38, 39, 40, 46];

const Component = (props) => {
    const { id, name, value, placeholder, errors, decimal, maxLength, location, className, maxValue,
        required, requiredMessage, onInputChanged, onStateChanged, readOnly, onMaxValueExceeded } = props;

    const tReadOnly = readOnly || false;
    const inputProps = {
        id, name, className, placeholder, autoComplete: Helper.TimeStamp(), type: "text",
        value, maxLength, readOnly: tReadOnly
    };

    const OnInputChanged = (e) => {
        const { name, value } = e.target;
        if (value) {
            let tmp = value.split(".");
            if (tmp.length > 2) return;
            if (decimal && tmp.length > 1 && tmp[1].length > 2) return;
        }
        onInputChanged && onInputChanged({ name, value });
        OnBlurChanged(e);
    };

    const OnBlurChanged = (e) => {
        const { name, value } = e.target;
        let clear = false;

        if (required && Helper.IsNullValue(value)) {
            onStateChanged && onStateChanged({ name, requiredMessage, clear });
        }

        clear = true;
        onStateChanged && onStateChanged({ name, requiredMessage, clear });

    };

    const OnKeyUp = (e) => {
        var charCode = e.which ? e.which : e.keyCode;
        let keyList = [...ControlKeyes, ...NumCodes, ...DigitCodes];
        if (decimal) keyList = [...keyList, 110, 190];
        if (!keyList.find((x) => x === charCode)) {
            e.preventDefault();
            return false;
        }
        let tmp = `${value}${e.key}`;

        if (maxValue && parseFloat(tmp) > parseFloat(maxValue)) {
            if (onMaxValueExceeded) onMaxValueExceeded(true);
            e.preventDefault();
            return false;
        }
        if (onMaxValueExceeded) onMaxValueExceeded(false);

        return true;
    };

    return (
        <input {...inputProps}
            onChange={OnInputChanged}
            onBlur={OnBlurChanged}
            onKeyDown={OnKeyUp}
            data-req={required}
            data-req-msg={requiredMessage}
            data-location={location}
            style={{
                borderColor: Helper.GetErrorBackColor(errors, id)
            }}
        />
    );
};

export default Component;
