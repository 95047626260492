import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (event) => {
        const selectedLanguage = event.target.value;
        i18n.changeLanguage(selectedLanguage);
    };

    return (
        <div>
            <div className='selectLang'>
                <select name="languageSelect" title="Change Language" onChange={changeLanguage} defaultValue={i18n.language}>
                    <option value="en">English</option>
                    {/* <option value="kn">Kannada</option>
                    <option value="te">Telegu</option>
                    <option value="ta">Tamil</option>
                    <option value="ml">Malayalam</option> */}
                </select>
            </div>

        </div>
    );
};

export default LanguageSwitcher;
