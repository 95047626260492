import React from 'react';

import LoanQuote from "screens/component/loanQuote";

const Component = (props) => {

    const { isOpen } = props;

    return (
        <div className={`popup ${isOpen ? 'active' : ''}`}>
            <div className="popupLoanQuote">
                <LoanQuote {...props} isPopup={isOpen} />
            </div>
        </div>
    );
};

export default Component;
