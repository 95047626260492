import { React } from "shared/common";
import Container from "screens/container";
import ProgressBar from "./childs/progressbar";
import { useSelector } from "react-redux";

import { Stage1, Stage2, Stage3, Stage4, Stage5 } from "./childs";

const Component = () => {

    const { stage } = useSelector(x => x.applyNowReducer);

    return (
        <>
            <Container headerType={'inner'}>
                <div id="container">
                    <div id="content">
                        <div className="widthfix">
                            <div className='quoteQuestionnaire'>
                                <div>
                                    <ProgressBar currentStage={1} />
                                    {stage === 1 && <Stage1 />}
                                    {stage === 2 && <Stage2 />}
                                    {stage === 3 && <Stage3 />}
                                    {stage === 4 && <Stage4 />}
                                    {stage === 5 && <Stage5 />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Container>
        </>
    )
}

export default Component;