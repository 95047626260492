import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { HomeScreen, TermsScreen, ApplyScreen } from "screens";
import { ReachUsScreen, WhoWeAreScreen, WhyUsScreen, LoanEMIChartScreen } from "screens/headertabs";

const RouteLinks = [
  { id: 1, path: "", Component: HomeScreen },
  { id: 2, path: "reachus", Component: ReachUsScreen },
  { id: 3, path: "whoweare", Component: WhoWeAreScreen },
  { id: 4, path: "whyus", Component: WhyUsScreen },
  { id: 5, path: "emichart", Component: LoanEMIChartScreen },
  /* { id: 6, path: "applyloan", Component: ApplyLoan }, */
  { id: 6, path: "apply", Component: ApplyScreen },
  { id: 7, path: "terms", Component: TermsScreen },
  //{ id: 8, path: "approval", Component: Approval },
  //{ id: 9, path: "loanquote", Component: LoanQuote },
];

function PrivateRoute({ children }) {
  let loggedin = sessionStorage.getItem("user");
  return loggedin ? children : <Navigate to="/" />;
}

const RendorRoutes = () => {
  return (
    <Routes>
      {RouteLinks.map((x) => {
        if (x.Session) {
          return <Route key={x.id} path={"/" + x.path} exact
            element={
              <PrivateRoute>
                <x.Component />
              </PrivateRoute>
            }
          />
        } else {
          return <Route key={x.id} path={"/" + x.path} exact
            element={<x.Component />} />
        }
      })}
    </Routes>
  );
};

export default RendorRoutes;