import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setApplyState } from "features/applyNow/applySlice";

const Component = () => {

    const dispatch = useDispatch();
    const { vehicleType } = useSelector(x => x.applyNowReducer);

    const OnVehicleTypeSelected = (e) => {
        dispatch(setApplyState({ vehicleType: e, stage: 2 }))
    }

    return (
        <div>
            <div className='quoteContentBlk'>
                <div className='stageHead'>
                    Considering a two wheeler purchase in the near future?
                </div>
                <input type='button' className={vehicleType === 'new' ? 'buttonNewBike active' : 'buttonNewBike'} value="Want to Buy a New Vehicle" onClick={() => OnVehicleTypeSelected('new')} />
                <input type='button' className={vehicleType === 'used' ? 'buttonUsedBike active' : 'buttonUsedBike'} value="Want to Buy a Used Vehicle" onClick={() => OnVehicleTypeSelected('used')} />

                <ul className='quoteUSPblk'>
                    <li className='quoteUSPblk'>
                        <div className='uspTextHead'>10,000+</div>
                        <div className='uspTextDesc'>Two Wheelers Funded</div>
                    </li>
                    <li className='quoteUSPblk'>&nbsp;</li>
                    <li className='quoteUSPblk'>
                        <div className='uspTextHead'>₹999/mth*</div>
                        <div className='uspTextDesc'>Easy & Convenient EMIs</div>
                    </li>
                </ul>

                <div className='approvalBenefit'>
                    <div className='benefitHeadText'>After a few questions, you'll unlock</div>
                    <div className='benefitText'>
                        AI generated custom quotation, picking the best rates from multiple financer
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Component;
