import { Link, React } from "shared/common";

const Component = () => {
    return (
        <div id="home_bottom_blk">
            <div className="widthfix">
                <ul className="botContactBlk">
                    <li className="botContactBlk">
                        <ul className="botContactTxtIcon">
                            <li className="botContactTxtIcon">
                                080 44634304<br />080 26496555
                            </li>
                            <li className="botContactTxtIcon">
                                <div className="phIcon"></div>
                            </li>
                        </ul>
                    </li>
                    <li className="botContactBlk">
                        <ul className="botContactTxtIcon">
                            <li className="botContactTxtIcon">
                                <span className="smallFont">Check Offers At:</span><br />gaadiplus.com
                            </li>
                            <li className="botContactTxtIcon">
                                <div className="linkIcon"></div>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div className="disclaimerTxt"><Link to="/terms">* Conditions Apply</Link></div>
                <div className="poweredTxt">Powered by <Link to="https://uttarana.com/" target="_blank">Moham Uttarana</Link></div>
            </div>
        </div>
    )
}

export default Component;