import React, { Link } from '../../shared/common';

const SeeHowPopScreen = ({ isOpen, onClose }) => {
    return (
        <div className={`popup ${isOpen ? 'active' : ''}`}>
            <div className="popupSeeHow">
                <div className='popSeeHowHead'>See How It Works</div>
                <div className='popSeeHowGraphic'></div>
                <div className='popSeeHowGraphic_sc'></div>
                <div className='popSeeHowTxt'>
                    Start your journey with GaadiPlus today. <br />More than just a new bike, you'll experience significant savings and the freedom to choose!
                </div>
                <button className="popButton" onClick={onClose}>Close</button>
                <Link to="/apply"><button className="popButton">Apply Now</button></Link>
            </div>
        </div>
    );
};

export default SeeHowPopScreen;
