import React, { useState } from 'react';
import { EmailInput, NumberInput, TextInput } from "components";
import Helper from "shared/helper";
import * as CommonApi from "services/CommonApi";

const RequestCallbackForm = ({ formId, isOpen, onClose }) => {

    const [initilized, setInitilized] = useState(false);
    const [row, setRow] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [errors, setErrors] = React.useState([]);

    const OnSubmit = async (e) => {
        e.preventDefault();

        setErrors([]);
        setError(null);

        const { errors, valid } = Helper.IsFormValid(formId);

        if (!valid) {
            setErrors(errors);
            setError("Please fix the errors and proceed");
            return;
        }

        global.Busy(true);
        let rslt = await CommonApi.Contact(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setErrors(rslt.errors);
            setError(rslt.statusText);
            return;
        } else {
            setSuccess(rslt.statusText);
            setRow({});
        }
    }

    const OnClose = (e) => {
        e.preventDefault();
        if (onClose) onClose();
    }

    const OnValueChanged = (e) => {
        const { name, value } = e;
        setRow((prev) => ({ ...prev, [name]: value }));
    }

    const OnStateChanged = (e) => {
        const { name, requiredMessage, clear } = e;
        setError(null);
        let tmp = errors.filter((x) => x.field !== name) || [];
        if (!clear) tmp.push({ field: name, value: requiredMessage });
        setErrors(tmp);
    }

    if (initilized) {
        setInitilized(false)
        setRow({});
        setError(null);
        setErrors([]);
        setSuccess(null);
    }

    React.useEffect(() => {
        setInitilized(true);
    }, [isOpen]);

    // const handleClick = () => {
    //     window.location.href = "tel:+919954036362";
    // };

    const handleClick = () => {
        const phoneNumber = '+918044634304';
        const link = document.createElement('a');
        link.href = `tel:${phoneNumber}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className={`popup ${isOpen ? 'active' : ''}`}>
            <div className="popupCallbackContent">

                {success ? (
                    <div className='popReply'>
                        Thank you for your request! <br />One of our representative will get back to you soon.
                        <div className='popReplyBtnBlk'><button className="popButton" onClick={OnClose}>Close</button></div>
                    </div>

                ) : (
                    <form id={formId}>
                        <div className='popCallNow'>
                            <button className="popButtonCallNow" onClick={handleClick}>Call Us Now</button>
                            <div className='orTxt'>Or</div>
                        </div>
                        <div className='popFormHead'>Request a Call Back</div>
                        <div className='popFormSubHead'>
                            Please fill the form and one of our representative will call you back shortly
                        </div>

                        <ul className='cbIIcol'>
                            <li className='cbIIcol'>
                                <TextInput
                                    id="firstName"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={Helper.ToString(row?.firstName)}
                                    onInputChanged={OnValueChanged}
                                    onStateChanged={OnStateChanged}
                                    errors={errors}
                                    required={true}
                                    requiredMessage={'First Name is required'}
                                />
                            </li>
                            <li className='cbIIcol'>
                                <TextInput
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={Helper.ToString(row?.lastName)}
                                    onInputChanged={OnValueChanged}
                                    onStateChanged={OnStateChanged}
                                    errors={errors}
                                    required={true}
                                    requiredMessage={'Last Name is required'}
                                />
                            </li>
                        </ul>

                        <EmailInput
                            id="email"
                            name="email"
                            placeholder="Email-Id"
                            value={Helper.ToString(row?.email)}
                            onInputChanged={OnValueChanged}
                            onStateChanged={OnStateChanged}
                            errors={errors}
                            required={true}
                            requiredMessage={'Email-Id is required'}
                        />

                        <NumberInput
                            maxLength={10}
                            id="phone"
                            name="phone"
                            placeholder={'Phone Number'}
                            value={Helper.ToString(row?.phone)}
                            onInputChanged={OnValueChanged}
                            onStateChanged={OnStateChanged}
                            errors={errors}
                            required={true}
                            requiredMessage={'Phone Number is required'}
                        />

                        {error &&
                            <div className="errormessage">{error}</div>
                        }

                        <div className="button-container">
                            <button className='popButton' onClick={OnSubmit} >Submit</button>
                            <button className="popButton" onClick={OnClose}>Close</button>
                        </div>

                    </form>

                )}
            </div>
        </div>
    );
};

export default RequestCallbackForm;
