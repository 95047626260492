import { PDFGeneratorApi, APIUrl } from "shared/common";

const Contact = async (input) => {
    return new Promise(async (resolve) => {
        let url = APIUrl(`common/contact`);

        try {
            const res = await fetch(url, {
                method: "POST",
                redirect: 'follow',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(input)
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            console.log(error);
            return resolve({ status: 900, statusText: error.message });
        }
    })
}

const GetMobileOtp = async (input, templateId) => {
    return new Promise(async (resolve) => {

        let url = APIUrl(`common/mobile/${input}/otp/${templateId}`);

        try {
            const res = await fetch(url, {
                method: "GET",
                redirect: 'follow',
                headers: { "Content-Type": "application/json" },
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            console.log(error);
            return resolve({ status: 900, statusText: error.message });
        }
    })
}

const ValidateOTP = async (email, mobile, otp) => {
    return new Promise(async (resolve) => {

        let url = APIUrl(`common/email/${email}/validateotp/${otp}`);
        if (mobile) {
            url = APIUrl(`common/mobile/${mobile}/validateotp/${otp}`);
        }

        try {
            const res = await fetch(url, {
                method: "GET",
                redirect: 'follow',
                headers: { "Content-Type": "application/json" },
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            console.log(error);
            return resolve({ status: 900, statusText: error.message });
        }
    })
}

const SendGaadiLoanQuote = async (input) => {
    return new Promise(async (resolve) => {
        let url = APIUrl(`common/quote`);

        try {
            const res = await fetch(url, {
                method: "POST",
                redirect: 'follow',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(input)
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            console.log(error);
            return resolve({ status: 900, statusText: error.message });
        }
    })
}

const GeneratePDF = async (input) => {
    return new Promise(async (resolve) => {
        let url = `${PDFGeneratorApi}/html/pdf/gaadiloanquote`;
        try {
            const res = await fetch(url, {
                method: "POST",
                redirect: 'follow',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(input)
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            console.log(error);
            return resolve({ status: 900, statusText: error.message });
        }
    })
}

const GetStates = async () => {
    return new Promise(async (resolve) => {

        let url = APIUrl(`common/states`);

        try {
            const res = await fetch(url, {
                method: "GET",
                redirect: 'follow',
                headers: { "Content-Type": "application/json" },
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            console.log(error);
            return resolve({ status: 900, statusText: error.message });
        }
    })
}

const GetCities = async (input) => {
    return new Promise(async (resolve) => {

        let url = APIUrl(`common/cities/${input}`);

        try {
            const res = await fetch(url, {
                method: "GET",
                redirect: 'follow',
                headers: { "Content-Type": "application/json" },
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            console.log(error);
            return resolve({ status: 900, statusText: error.message });
        }
    })
}

const GetVehicleManufacturers = async () => {
    return new Promise(async (resolve) => {

        let url = APIUrl(`common/vehiclemanufacturers`);

        try {
            const res = await fetch(url, {
                method: "GET",
                redirect: 'follow',
                headers: { "Content-Type": "application/json" },
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            console.log(error);
            return resolve({ status: 900, statusText: error.message });
        }
    })
}

const GetVehicleModels = async (input) => {
    return new Promise(async (resolve) => {

        let url = APIUrl(`common/vehiclemodels/${input}`);

        try {
            const res = await fetch(url, {
                method: "GET",
                redirect: 'follow',
                headers: { "Content-Type": "application/json" },
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            console.log(error);
            return resolve({ status: 900, statusText: error.message });
        }
    })
}

const GetVehicleVariants = async (input) => {
    return new Promise(async (resolve) => {

        let url = APIUrl(`common/vehiclevariants/${input}`);

        try {
            const res = await fetch(url, {
                method: "GET",
                redirect: 'follow',
                headers: { "Content-Type": "application/json" },
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            console.log(error);
            return resolve({ status: 900, statusText: error.message });
        }
    })
}

export {
    Contact,
    GetMobileOtp,
    ValidateOTP,
    SendGaadiLoanQuote,
    GeneratePDF,
    GetStates,
    GetCities,
    GetVehicleManufacturers,
    GetVehicleModels,
    GetVehicleVariants
};
