import React from "react";
import Helper from "shared/helper";

const Component = (props) => {
    const { id, name, value, options, displayId, valueId, errors, required, excludeFirst,
        requiredMessage, onInputChanged, location, validIndex, className, title } = props;
    const inputProps = { id, name, title, autoComplete: Helper.TimeStamp(), value: Helper.ToString(value), className };


    const OnInputChanged = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        onInputChanged && onInputChanged({ name, value });
    };

    return (
        <>
            <select
                {...inputProps}
                data-sel-id={id}
                data-sel-req={required}
                data-sel-msg={requiredMessage}
                data-location={location}
                data-sel-idx={validIndex}
                style={{
                    borderColor: Helper.GetErrorBackColor(errors, id)
                }}
                onChange={(e) => OnInputChanged(e)}
            >
                {!Helper.IsArrayNull(options) &&
                    options.map((x, index) => {
                        if (excludeFirst !== index) {
                            return <option key={index} value={x[valueId]}>
                                {x[displayId]}
                            </option>
                        } else {
                            return <option key={index} value={x[valueId]}></option>
                        }
                    })
                }
            </select>
        </>
    );
}

export default Component;
