import { React } from "shared/common";
import { useSelector } from "react-redux";

const Component = () => {

    const { stage } = useSelector(x => x.applyNowReducer);

    return (
        <div className="progressBar">
            {[1, 2, 3, 4, 5].map(x => (
                <div key={x} className={`progress-block ${x <= stage ? 'active' : ''}`} />
            ))}
        </div>
    );
};

export default Component;
