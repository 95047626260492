import React from "react";
import Slider from 'react-slick';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "screens/headers";
import Footer from "screens/footers";
import GetGPSLocation from "./gpslocation";

//export const APIUrl = "https://core.mohamcapital.in/";

// let hostname = window.location.hostname;

let prodItems = ["gaadiplus.com", "www.gaadiplus.com"];
let stageItems = ["staging.gaadiplus.com", "www.staging.gaadiplus.com"];
let devItems = ["dev.gaadiplus.com", "www.dev.gaadiplus.com"];

let environment = 'DEV';
const PDFGeneratorApi = "https://pdf.mohamcapital.com";

const APIUrl = (params) => {
    let url;

    let hostname = window.location.hostname;

    if (hostname === "localhost") {
        //url = "http://localhost:4000/";
        //url = "https://core.mohamcapital.in/";
        url = "https://dev-core.gaadiplus.com/";
        environment = 'dev';
    } else if (prodItems.findIndex((x) => x === hostname) > -1) {
        url = "https://core.gaadiplus.com/";
        environment = 'prod';
    } else if (stageItems.findIndex((x) => x === hostname) > -1) {
        url = "https://staging-core.gaadiplus.com/";
        environment = 'staging';
    } else if (devItems.findIndex((x) => x === hostname) > -1) {
        url = "https://dev-core.gaadiplus.com/";
        environment = 'dev';
    }

    return `${url}${params}`;
}


export {
    React, Link, Slider, useNavigate, useLocation, Header, Footer, APIUrl,
    PDFGeneratorApi, environment, GetGPSLocation
};