import { React } from "shared/common";
import Container from "screens/container";

const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container">
                    <div id="content">
                        <div className="BC_blk">
                            <div className="widthfix">
                                <div className="BC_txt">Why Gaadi plus</div>
                                <div className="BC_descTxt">Efficiency. Convenience. Reliability.</div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className="reachUsBlk">
                                <div className="txtBold mar_5">
                                    Specialized Expertise:
                                </div>
                                <div className="txtNormal mar_20">
                                    With years of experience in the industry, our team understands the ins and outs of two-wheeler financing. We'll work with you every step of the way to find the perfect loan option for your needs.
                                </div>
                                <div className="txtBold mar_5">
                                    Exclusive Additional Discount:
                                </div>
                                <div className="txtNormal mar_20">
                                    Our Rs. 5000 discount offer is just one of the many ways we strive to make two-wheeler ownership more accessible. With GaadiPlus.com, you'll enjoy unbeatable savings that you won't find anywhere else.
                                </div>
                                <div className="txtBold mar_5">
                                    Trusted Partnerships:
                                </div>
                                <div className="txtNormal mar_20">
                                    We've partnered with top lenders to offer you competitive loan options and flexible repayment terms. Plus, our network of dealerships ensures that you'll have plenty of options to choose from when it comes to finding the perfect bike or scooter.
                                </div>
                                <div className="txtBoldBig mar_5">
                                    Get Started Today
                                </div>
                                <div className="txtNormal">
                                    Ready to hit the road on your new two-wheeler? Start by browsing our selection of bikes and scooters from leading dealerships across the country. Then, simply apply for financing through our website and unlock exclusive savings with our additional Rs. 5000 discount offer. With GaadiPlus.com, your next adventure is just a ride away.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;