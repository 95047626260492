import React from "react";
import Helper from "shared/helper";
import MaskedInput from 'react-text-mask';

const DigitCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
const NumCodes = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
const ControlKeyes = [8, 9, 13, 17, 35, 36, 37, 38, 39, 40, 46];

const Component = (props) => {
    const { id, name, value, placeholder, errors, location,
        required, requiredMessage, onInputChanged, onStateChanged } = props;

    const inputProps = {
        id, name, placeholder, autoComplete: Helper.TimeStamp(),
        type: "text", text: Helper.ToString(value)
    };

    const OnInputChanged = (e) => {
        const { name, value } = e.target;
        onInputChanged && onInputChanged({ name, value });
        OnBlurChanged(e);
    };

    const OnBlurChanged = (e) => {
        const { name, value } = e.target;
        let clear = false;

        if (required && Helper.IsNullValue(value)) {
            onStateChanged && onStateChanged({ name, requiredMessage, clear });
        }

        if (!Helper.IsNullValue(value)) {
            let formatVal = Helper.ToUSAPhone(value);
            if (Helper.IsNullValue(formatVal)) {
                onStateChanged && onStateChanged({ name, requiredMessage, clear });
            } else {
                clear = true;
                onStateChanged && onStateChanged({ name, requiredMessage, clear });
            }
        }

    };

    const OnKeyUp = (e) => {
        var charCode = e.which ? e.which : e.keyCode;
        let keyList = [...ControlKeyes, ...NumCodes, ...DigitCodes];
        if (!keyList.find((x) => x === charCode)) {
            e.preventDefault();
            return false;
        }
        return true;
    };

    return (
        <MaskedInput {...inputProps}
            autoComplete={"off"}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            onChange={OnInputChanged}
            onBlur={OnBlurChanged}
            onKeyDown={OnKeyUp}
            data-req={required}
            data-req-msg={requiredMessage}
            data-location={location}
            style={{
                borderColor: Helper.GetErrorBackColor(errors, id)
            }}
        />
    );
};

export default Component;
