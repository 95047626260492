export const TenureList = [12, 18, 24, 30, 36];

export const BrandList = [
    { id: 0, text: "Select Brand", value: "" },
    { id: 1, text: "Hero MotoCorp", value: "Hero MotoCorp" },
    { id: 2, text: "Bajaj Auto", value: "Bajaj Auto" },
    { id: 3, text: "TVS Motors", value: "TVS Motors" },
    { id: 4, text: "Royal Enfield", value: "Royal Enfield" },
    { id: 5, text: "KTM", value: "KTM" },
    { id: 6, text: "OLA", value: "OLA" },
    { id: 7, text: "Ather", value: "Ather" },
    { id: 8, text: "Yamaha Motors", value: "Yamaha Motors" },
    { id: 9, text: "Suzuki Motorcycle", value: "Suzuki Motorcycle" },
    { id: 10, text: "Piaggio India", value: "Piaggio India" },
    { id: 11, text: "Honda Motorcycle", value: "Honda Motorcycle" },
    { id: 12, text: "Mahindra Two Wheelers", value: "Mahindra Two Wheelers" }
];

export const InterestRates = [
    { id: 0, value: "9.99%", text: "9.99% (Cibil 750+)" },
    { id: 1, value: "10.99%", text: "10.99% (Cibil 700-720)" },
    { id: 2, value: "11.99%", text: "11.99% (Cibil 680-700)" },
    { id: 3, value: "12.99%", text: "12.99% (Cibil 650-680)" }
];