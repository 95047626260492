import LanguageSwitcher from "screens/component/LanguageSwitcher";
import { React, Link } from "shared/common";

// Why GaadiPlus
const mnu1 = [
    "/whyus"
];
// Who We Are
const mnu2 = [
    "/whoweare"
];

// Reach Us
const mnu3 = [
    "/reachus"
];

const Component = (props) => {

    const { loc, togglePopup } = props;

    const IsActive = (mnu) => {
        return mnu.findIndex((x) => x === loc) > -1 ? "dropbtn active" : "dropbtn";
    };

    return (
        <>

            <div className="login_btnLabel">
                <input type="button" className="login_button" value="Call Us Now" onClick={togglePopup} />
            </div>

            <div className="languageSelect">
                <LanguageSwitcher />
            </div>

            <div className="topnav">
                <div className="dropdown">
                    <Link to="/whyus"><button className={IsActive(mnu1)}>
                        Why GaadiPlus
                    </button></Link>
                </div>
                <div className="dropdown">
                    <Link to="/whoweare"><button className={IsActive(mnu2)}>
                        Who We Are
                    </button></Link>
                </div>
                <div className="dropdown">
                    <Link to="/reachus"><button className={IsActive(mnu3)}>
                        Reach Us
                    </button></Link>
                </div>
            </div>
        </>
    )
}

export default Component;