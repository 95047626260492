import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import ObjectStateReducer from 'features/objectState/objectStateSlice';
import EnumReducer from 'features/enum/enumSlice';
import ApplyNowReducer from "features/applyNow/applySlice";

const rootReducer = combineReducers({
    objectStateReducer: ObjectStateReducer,
    enumReducer: EnumReducer,
    applyNowReducer: ApplyNowReducer
})

const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ["objectStateReducer"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})

export const persistor = persistStore(store);