// import LanguageSwitcher from "screens/component/LanguageSwitcher";
import { React, Link } from "shared/common";

// Why GaadiPlus
const mnu1 = [
    "/whyus"
];
// Who We Are
const mnu2 = [
    "/whoweare"
];

// Reach Us
const mnu3 = [
    "/reachus"
];

const Component = (props) => {

    const { loc, togglePopup } = props;

    const IsActive = (mnu) => {
        return mnu.findIndex((x) => x === loc) > -1 ? "dropbtn active" : "dropbtn";
    };

    const OnTogglePopup = (e) => {
        e.preventDefault();
        togglePopup();
    }

    return (
        <div>
            <header className="headerResponsive" >
                <div className="navBlockResponsive">

                    <div className="loginBlkResponsive">

                        <Link to="#" onClick={OnTogglePopup}><div className="loginLogoutBtnTxt">Call Now</div></Link>
                        {/* <div className="languageSelect">
                            <LanguageSwitcher />
                        </div> */}
                    </div>

                    <div className="topnav" id="myTopnav">
                        <Link to="/">
                            <div className="gaadiplusLogo logoPosResponsive"></div>
                        </Link>
                        <Link to="/whyus"><div className="dropdown">
                            <button className={IsActive(mnu1)}>
                                Why GaadiPlus
                            </button>
                        </div></Link>
                        <Link to="/whoweare"><div className="dropdown">
                            <button className={IsActive(mnu2)}>
                                Who We Are
                            </button>
                        </div></Link>

                        <Link to="/reachus"><div className="dropdown">
                            <button className={IsActive(mnu3)}>
                                Reach Us
                            </button>
                        </div></Link>

                        <Link
                            to="#"
                            className="icon"
                            onClick={() => window.myFunction()}
                        ></Link>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Component;