import { React, Link, useNavigate } from "shared/common";
import { useDispatch, useSelector } from "react-redux";
import { setApplyState } from "features/applyNow/applySlice";
import { TextInput, NumberInput } from "components";
import Helper from "shared/helper";
import * as CommonApi from "services/CommonApi";
import { setObjectState } from "features/objectState/objectStateSlice";

const Component = () => {

    const dispatch = useDispatch();
    const [errors, setErrors] = React.useState([]);
    const [error, setError] = React.useState(null);
    const { brandName, vehiclePrice, downPayment, fullName,
        mobileNumber, otpNumber, otpSent, otValidated } = useSelector(x => x.applyNowReducer);
    const [showReSendOTP, setShowReSendOTP] = React.useState(false);
    const myTimeout = React.useRef(null);
    const NavigateTo = useNavigate();

    const OnStageMoved = (e, num) => {
        if (e) e.preventDefault();
        dispatch(setApplyState({ stage: num }))
    }

    const OnInputChange = (e) => {
        const { name, value } = e;
        dispatch(setApplyState({ [name]: value }));
    };

    const OnStateChanged = (e) => {
        const { name, requiredMessage, clear } = e;
        let tmp = errors.filter((x) => x.field !== name) || [];
        if (!clear) tmp.push({ field: name, value: requiredMessage });
        setErrors(tmp);
    }

    const OnGetOTPClicked = async (e) => {
        e.preventDefault();

        setError(null);
        setErrors([]);

        if (Helper.IsNullValue(mobileNumber)) {
            setErrors([{ field: "mobileNumber", value: "Mobile Number is required" }]);
            return;
        }

        global.Busy(true);
        let rslt = await CommonApi.GetMobileOtp(mobileNumber, 56);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }
        dispatch(setApplyState({ otpSent: true, otValidated: true }));
        setShowReSendOTP(false);
        myTimeout.current = setTimeout(() => {
            setShowReSendOTP(true);
            dispatch(setApplyState({ otpSent: false }));
        }, 30000)
    }

    const OnSubmitClicked = async (e) => {
        e.preventDefault();

        setErrors([]);

        const { errors, valid } = Helper.IsFormValid('formStage5');

        if (!valid) {
            setErrors(errors);
            return;
        }

        global.Busy(true);
        let rslt = await CommonApi.ValidateOTP(null, mobileNumber, parseInt(otpNumber));
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }

        const currentTime = Helper.ToDateFormat(new Date(), "DD/MM/YYYY HH:mm:ss");

        let data = { currentTime, brandName, onRoadPrice: vehiclePrice, downPayment, mobileNumber, isOTPValid: true };

        global.Busy(true);
        await CommonApi.SendGaadiLoanQuote(data);
        global.Busy(false);

        setShowReSendOTP(false);
        dispatch(setApplyState({ otpSent: false }));
        clearTimeout(myTimeout.current);
        dispatch(setObjectState(data));
        return NavigateTo('/emichart')

    }

    React.useEffect(() => {
        setError(null);
        dispatch(setApplyState({ otpSent: false }));
    }, [dispatch]);

    return (
        <div>
            <div className='quoteContentBlk'>
                <div className='stageHead'>
                    Please provide your name and verify your phone number
                    <form id="formStage5" className="form" onSubmit={OnSubmitClicked}>
                        <div className="form-group">

                            <TextInput
                                id="fullName"
                                name="fullName"
                                placeholder={''}
                                value={Helper.ToString(fullName)}
                                className={`form-control ${fullName && 'has-value'} name-field`}
                                onInputChanged={(e) => OnInputChange(e)}
                                onStateChanged={OnStateChanged}
                                errors={errors}
                                required={true}
                                requiredMessage={'Name is required'}
                            />

                            <label className={`form-label ${fullName && 'shrink'}`}>Name</label>
                        </div>

                        <ul className='quoteIIcol60by40'>
                            <li className='quoteIIcol60by40'>
                                <div className="form-group">

                                    <NumberInput
                                        maxLength={10}
                                        id="mobileNumber"
                                        name="mobileNumber"
                                        placeholder={''}
                                        value={Helper.ToString(mobileNumber)}
                                        onInputChanged={(e) => OnInputChange(e)}
                                        onStateChanged={OnStateChanged}
                                        errors={errors}
                                        required={true}
                                        requiredMessage={'Mobile Number is required'}
                                        className={`form-control ${mobileNumber && 'has-value'} phone-field`}
                                    />

                                    <label className={`form-label ${mobileNumber && 'shrink'}`}>Phone</label>
                                    <button type="button" className="get-otp-button" onClick={OnGetOTPClicked}>
                                        Get OTP
                                    </button>
                                </div>
                            </li>
                            <li className='quoteIIcol60by40'>
                                <div className="form-group">
                                    <NumberInput
                                        maxLength={4}
                                        id="otpNumber"
                                        name="otpNumber"
                                        placeholder={''}
                                        value={Helper.ToString(otpNumber)}
                                        onInputChanged={(e) => OnInputChange(e)}
                                        onStateChanged={OnStateChanged}
                                        errors={errors}
                                        required={true}
                                        requiredMessage={'Mobile Number is required'}
                                        className={`form-control ${otpNumber && 'has-value'} otp-field`}
                                    />

                                    <label className={`form-label ${otpNumber && 'shrink'}`}>OTP</label>
                                </div>
                            </li>
                        </ul>
                        {showReSendOTP && (
                            <div className="resendOTP_Blk">
                                <input type="button" className="resendOTP_button" value="Re-Send OTP" onClick={OnGetOTPClicked} />
                            </div>
                        )}
                        {error && <div className="errorMsg" style={{ marginTop: "10px", marginBottom: "0px" }}>{error}</div>}
                        {otpSent && <div className="successMsg" style={{ marginTop: "10px", marginBottom: "0px" }}>OTP sent successfully, please check your mobile</div>}

                        <div className='formButtonLabel'>
                            <Link to="#" onClick={(e) => OnStageMoved(e, 4)}><div className='prevButton'></div></Link>
                            <button disabled={!otValidated} type="submit" className="submit-button">Generate Quote</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>

    );
};

export default Component;
