import { React } from "shared/common";
import Container from "screens/container";

const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container">
                    <div id="content">
                        <div className="BC_blk">
                            <div className="widthfix">
                                <div className="BC_txt">Terms &amp; Conditions</div>
                                <div className="BC_descTxt">Clarity. Assurance. Transparency.</div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className="termsConditions">
                                <div className="txtBoldBig mar_5">
                                    On-Road Price
                                </div>
                                <div className="txtNormal mar_20">
                                    The on-road price of the vehicle varies based on the model and dealership.
                                </div>
                                <div className="txtBoldBig mar_5">
                                    Offer Price
                                </div>
                                <div className="txtNormal mar_20">
                                    <ol type="1" className="marLeft20">
                                        <li className="padBot10">Loan Amount 50,000 and above: <br />Exclusive GaadiPlus discount of Rs. 5,000.</li>
                                        <li className="padBot10">Loan Amount 20,000 to 49,999: <br />Exclusive GaadiPlus discount of Rs. 2,000.</li>
                                        <li className="padBot10">Loan Amount 10,000 to 19,999: <br />Exclusive GaadiPlus discount of Rs. 1,000.</li>
                                    </ol>
                                </div>
                                <div className="txtBoldBig mar_5">
                                    Loan Approval
                                </div>
                                <div className="txtNormal mar_20">
                                    Approval of the loan is subject to our lending partner's terms and conditions. We do not guarantee loan approval. If the customer's loan application is rejected, they will not be eligible to avail of the offer.
                                </div>
                                <div className="txtBoldBig mar_5">
                                    EMI Payment Condition
                                </div>
                                <div className="txtNormal mar_20">
                                    The offer is valid only if the customer pays their monthly EMI on time. If the customer makes late payment of 2 EMI, the offer will be discontinued.
                                </div>
                                <div className="txtBoldBig mar_5">
                                    Eligibility
                                </div>
                                <div className="txtNormal mar_20">
                                    This offer is applicable only to finance customers.
                                </div>
                                <div className="txtBoldBig mar_5">
                                    Dealer Selection
                                </div>
                                <div className="txtNormal mar_20">
                                    Customers can choose any dealer they prefer.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;