import { React } from "shared/common";
import Container from "screens/container";
import LoanQuote from '../../component/loanQuote';
import EMIChart from "../../component/emiChart";

const Component = () => {

    return (
        <>
            <Container headerType={'inner'}>
                <div id="container">
                    <div id="content">
                        <div className="widthfix">
                            <div className="loanEmiChart">
                                <ul className="loanQuoteEMIChartBlk">
                                    {/* Loan Quote */}
                                    <li className="loanQuoteBlk">
                                        <LoanQuote />
                                    </li>
                                    {/* EMI Chart */}
                                    <li className="emiChartBlk">
                                        <EMIChart />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;