import React from "react";
import RendorRoutes from "./routes";
//import { useDispatch } from "react-redux";
//import { fetchVehicleManufacturers } from "features/enum/enumSlice";

global.Busy = (bBusy) => {
    var x = document.getElementById("busyloader");
    if (x) {
        if (bBusy) {
            x.className = "loader display-block";
        } else {
            x.className = "loader display-none";
        }
    }
};

function App() {

    /* const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchVehicleManufacturers());
    }, [dispatch]); */

    return <RendorRoutes />;
}

export default App;
