

import Helper from "shared/helper";
import { SelectInput } from 'components';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from 'react-router-dom';
import { TenureList, InterestRates } from "shared/support";
import { setObjectState } from "features/objectState/objectStateSlice";

const Component = (props) => {

    const { isPopup } = props;

    const row = useSelector(state => state.objectStateReducer) || {};
    const dispatch = useDispatch();
    const NavigateTo = useNavigate();

    const OnValueChanged = (e, isFloat) => {
        const { name, value } = e;
        dispatch(setObjectState({ [name]: isFloat ? Helper.ToFloat(value) : value }));
    }

    const OnNavigateChart = (e, url) => {
        e.preventDefault(e);
        isPopup && NavigateTo(url);
    }

    return (
        <>
            {row.showSpecialDiscount && <div className="gaadiPlusErrorMsg">{row.showSpecialDiscount}</div>}
            <ul className='loanQuoteResults'>
                <li className='loanQuoteResults'>
                    <ul className='quoteData'>
                        <li className='quoteData'>Particulars</li>
                        <li className='quoteData'>Amount</li>
                        <li className='quoteData'>On Road Price</li>
                        <li className='quoteData'>{Helper.INRCurrencyFormat(row?.onRoadPrice || 0, true)}</li>
                        <li className='quoteData'>Down Payment</li>
                        <li className='quoteData'>{Helper.INRCurrencyFormat(row?.downPayment || 0, true)}</li>
                        <li className='quoteData'>Stamp Duty &amp; Verification</li>
                        <li className='quoteData'>{Helper.INRCurrencyFormat(row?.stampDutyCharges || 0, true)}</li>
                        <li className='quoteData'>Processing Fee (Nil)</li>
                        <li className='quoteData'>0</li>
                        <li className='quoteData'>Loan Amount</li>
                        <li className='quoteData'>{Helper.INRCurrencyFormat(row?.loanAmtBeforeDiscount || 0, true)}</li>
                        <li className='quoteData'>Total Payable (Principal+Interest) <span className='SmallTxt'>Before GaadiPlus Benefit</span></li>
                        <li className='quoteData'>{Helper.INRCurrencyFormat(row?.totalPaymentBeforeDiscount || 0, true)}</li>
                        <li className='quoteData'>Gaadi Plus Benefit</li>
                        <li className='quoteData'>{Helper.INRCurrencyFormat(row?.specialDiscount, true)}</li>
                        <li className='quoteData'>Total Payable (Principal+Interest) <span className='SmallTxt'>After GaadiPlus Benefit</span></li>
                        <li className='quoteData'>{Helper.INRCurrencyFormat(row?.totalPaymentAfterDiscount || 0, true)}</li>
                    </ul>
                </li>

                <li className='loanQuoteResults'>

                    <ul className='intRateTenureBlk'>
                        {/* Interest Rate Content */}
                        <li className='intRateTenureBlk'>
                            <div className='intTenureHeading'>Interest Rate</div>
                            <div className='intTenureContent'>
                                <ul className="intRateStyle">
                                    <div className="interestRateSelectField">

                                        <SelectInput
                                            id="interestRate"
                                            name="interestRate"
                                            value={Helper.ToString(row?.interestRate)}
                                            options={InterestRates}
                                            displayId={"text"}
                                            valueId={"value"}
                                            onInputChanged={OnValueChanged}
                                        />

                                    </div>
                                </ul>
                            </div>
                        </li>
                        {/* Tenure Content */}
                        <li className='intRateTenureBlk'>
                            <div className='intTenureHeading'>Tenure (In Months)</div>
                            <div className='intTenureContent'>
                                <ul className="tenureStyle">
                                    {TenureList.map((x) => (
                                        <li key={x} onClick={(e) => {
                                            e.preventDefault();
                                            OnValueChanged({ name: 'tenure', value: x, isFloat: true })
                                        }} className={row?.tenure === x ? 'active' : ''}>
                                            {x}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </li>
                    </ul>

                    <ul className="resultEMI">
                        <li className="resultEMI">
                            <div className="resultEMIHeading">EMI for {row?.interestRate} &amp; {row?.tenure} Mts</div>
                        </li>
                        <li className="resultEMI">
                            <div>
                                <div className="resultEMIAnswer">
                                    {Helper.INRCurrencyFormat(row?.displayEmiAmount, true)}
                                </div>
                            </div>
                        </li>
                        <li className="resultEMI">
                            <div className='emiChartTxt'>
                                <Link to="#" onClick={(e) => OnNavigateChart(e, '/emichart')}>View EMI Chart</Link>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    )

}

export default Component;