import { React, Link } from "shared/common";
import { useDispatch, useSelector } from "react-redux";
import { setApplyState } from "features/applyNow/applySlice";
import { TextInput, NumberInput } from "components";
import Helper from "shared/helper";

const Component = () => {

    const dispatch = useDispatch();
    const [errors, setErrors] = React.useState([]);
    const [downPaymentInValid, setDownPaymentInValid] = React.useState(false);
    const { vehicleFullName, vehiclePrice, downPayment } = useSelector(x => x.applyNowReducer);

    const OnStageMoved = (e, num) => {
        if (e) e.preventDefault();
        dispatch(setApplyState({ stage: num }))
    }

    const OnInputChange = (e) => {
        const { name, value } = e;
        dispatch(setApplyState({ [name]: parseInt(value) }));
    };

    const OnStateChanged = (e) => {
        const { name, requiredMessage, clear } = e;
        let tmp = errors.filter((x) => x.field !== name) || [];
        if (!clear) tmp.push({ field: name, value: requiredMessage });
        setErrors(tmp);
        setDownPaymentInValid(false);
    }

    const OnSubmitClicked = (e) => {
        e.preventDefault();

        setDownPaymentInValid(false);

        setErrors([]);

        const { errors, valid } = Helper.IsFormValid('formStage4');

        if (!valid) {
            setErrors(errors);
            return;
        }

        if (downPayment > vehiclePrice || downPayment > vehiclePrice * 0.95) {
            setDownPaymentInValid(true);
            return;
        }

        dispatch(setApplyState({ stage: 5 }))

    }

    React.useEffect(() => {
        setDownPaymentInValid(false);
    }, []);

    return (
        <div>
            <div className='quoteContentBlk'>
                <div className='stageHead'>
                    Please indicate the upfront amount you are willing to pay
                    <form id="formStage4" className="form" onSubmit={OnSubmitClicked}>
                        <div className="form-group">

                            <TextInput
                                id="address"
                                name="address"
                                placeholder={''}
                                value={Helper.ToString(vehicleFullName)}
                                className={`form-control ${vehicleFullName && 'has-value'} brand-field`}
                                readOnly={true}
                            />

                            <label className={`form-label ${vehicleFullName && 'shrink'}`}>Brand</label>
                        </div>

                        <ul className='quoteIIcol'>
                            <li>
                                <div className="form-group">
                                    <div className="infoIconPrice">
                                        <span className="tooltipText">Price mentioned is tentative price. Please update, if you know the actual price</span>
                                    </div>

                                    <NumberInput
                                        maxLength={10}
                                        id="vehiclePrice"
                                        name="vehiclePrice"
                                        placeholder={''}
                                        value={Helper.ToString(vehiclePrice)}
                                        onInputChanged={(e) => OnInputChange(e)}
                                        onStateChanged={OnStateChanged}
                                        errors={errors}
                                        required={true}
                                        requiredMessage={'On Price is required'}
                                        className={`form-control ${vehiclePrice && 'has-value'} price-field`}
                                    />


                                    <label className={`form-label ${vehiclePrice && 'shrink'}`}>Price</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">

                                    <NumberInput
                                        maxLength={10}
                                        id="downPayment"
                                        name="downPayment"
                                        placeholder={''}
                                        value={Helper.ToString(downPayment)}
                                        onInputChanged={(e) => OnInputChange(e)}
                                        onStateChanged={OnStateChanged}
                                        errors={errors}
                                        required={true}
                                        requiredMessage={'Downpayment is required'}
                                        className={`form-control ${downPayment && 'has-value'} downpayment-field`}
                                    />

                                    <label className={`form-label ${downPayment && 'shrink'}`}>Down Payment</label>
                                </div>
                            </li>
                        </ul>
                        {downPaymentInValid && <div className="errorMsg" style={{ marginTop: "10px", marginBottom: "0px" }}>Down payment cannot exceed 95% of on-road price</div>}
                        <div className='formButtonLabel'>
                            <Link to="#" onClick={(e) => OnStageMoved(e, 3)}><div className='prevButton'></div></Link>
                            <button type="submit" className="submit-button">Next</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
};

export default Component;
