import { React, Link } from "shared/common";
import { useDispatch, useSelector } from "react-redux";
import { setApplyState } from "features/applyNow/applySlice";
import { SelectInput } from "components";
import Helper from "shared/helper";
import { fetchVehicleManufacturers, fetchVehicleModels, fetchVehicleVariants } from "features/enum/enumSlice";

const Component = () => {

    const dispatch = useDispatch();
    const [initialize, setInitialize] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const { brandId, modelId, variantId } = useSelector(x => x.applyNowReducer);
    const { vehicleManufacturers, vehicleModels, vehicleVariants } = useSelector(state => state.enumReducer);

    const OnStageMoved = (e, num) => {
        if (e) e.preventDefault();
        dispatch(setApplyState({ stage: num }))
    }

    const OnInputChange = (e) => {
        const { name, value } = e;
        dispatch(setApplyState({ [name]: value }));

        if (name === 'brandId') {

            let findOne = vehicleManufacturers.find(x => x._id === value)?.text;
            dispatch(setApplyState({ brandName: findOne }));

            dispatch(fetchVehicleModels(value));
        } else if (name === 'modelId') {

            let findOne = vehicleModels.find(x => x._id === value)?.text;
            dispatch(setApplyState({ modelName: findOne }));

            dispatch(fetchVehicleVariants(value));
        } else if (name === 'variantId') {
            let findOne = vehicleVariants.find(x => x._id === value)?.text;
            dispatch(setApplyState({ variantName: findOne }));
        }
    };

    const OnStateChanged = (e) => {
        const { name, requiredMessage, clear } = e;
        let tmp = errors.filter((x) => x.field !== name) || [];
        if (!clear) tmp.push({ field: name, value: requiredMessage });
        setErrors(tmp);
    }

    const OnSubmitClicked = (e) => {
        e.preventDefault();

        setErrors([]);

        const { errors, valid } = Helper.IsFormValid('formStage2');

        if (!valid) {
            setErrors(errors);
            return;
        }

        dispatch(setApplyState({ stage: 4 }))

    }

    if (initialize) {
        setInitialize(false);
        dispatch(fetchVehicleManufacturers());
    }

    React.useEffect(() => {
        setInitialize(true);
    }, []);

    return (
        <div>
            <div className='quoteContentBlk'>
                <div className='stageHead'>
                    Kindly choose the motorcycle brand you wish to purchase
                    <form id="formStage1" className="form" onSubmit={OnSubmitClicked}>
                        <div className="form-group">

                            <SelectInput
                                excludeFirst={0}
                                id="brandId"
                                name="brandId"
                                value={Helper.ToString(brandId)}
                                options={vehicleManufacturers}
                                displayId={"displayname"}
                                valueId={"_id"}
                                onInputChanged={OnInputChange}
                                onStateChanged={OnStateChanged}
                                errors={errors}
                                required={false}
                                placeholder={''}
                                className={`form-control ${brandId && 'has-value'} brand-field`}
                                validIndex={1}
                            />

                            <label className={`form-label ${brandId && 'shrink'}`}>Select Brand</label>
                        </div>

                        <ul className='quoteIIcol'>
                            <li>
                                <div className="form-group">

                                    <SelectInput
                                        excludeFirst={0}
                                        id="modelId"
                                        name="modelId"
                                        value={Helper.ToString(modelId)}
                                        options={vehicleModels}
                                        displayId={"displayname"}
                                        valueId={"_id"}
                                        onInputChanged={OnInputChange}
                                        onStateChanged={OnStateChanged}
                                        errors={errors}
                                        required={false}
                                        placeholder={''}
                                        className={`form-control ${modelId && 'has-value'} model-field`}
                                        validIndex={1}
                                    />

                                    <label className={`form-label ${modelId && 'shrink'}`}>Select Model</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">

                                    <SelectInput
                                        excludeFirst={0}
                                        id="variantId"
                                        name="variantId"
                                        value={Helper.ToString(variantId)}
                                        options={vehicleVariants}
                                        displayId={"displayname"}
                                        valueId={"_id"}
                                        onInputChanged={OnInputChange}
                                        onStateChanged={OnStateChanged}
                                        errors={errors}
                                        required={false}
                                        placeholder={''}
                                        className={`form-control ${variantId && 'has-value'} variant-field`}
                                        validIndex={1}
                                    />


                                    <label className={`form-label ${variantId && 'shrink'}`}>Select Variant</label>
                                </div>
                            </li>
                        </ul>
                        <div className='formButtonLabel'>
                            <Link to="#" onClick={(e) => OnStageMoved(e, 2)}><div className='prevButton'></div></Link>
                            <button type="submit" className="submit-button">Next</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
};

export default Component;

