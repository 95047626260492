import { React } from "shared/common";
import Container from "screens/container";

const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container">
                    <div id="content">
                        <div className="BC_blk">
                            <div className="widthfix">
                                <div className="BC_txt">Who We Are</div>
                                <div className="BC_descTxt">Visionaries. Dedication. Impact.</div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className="whoWeAreBlk">
                                <div className="txtNormal mar_20">
                                    Welcome to GaadiPlus.com, your premier destination for hassle-free two-wheeler financing solutions. At GaadiPlus.com, we understand the joy and convenience that comes with owning your own ride, and we're dedicated to making that dream a reality for you.
                                </div>
                                <div className="txtBoldBig mar_5">
                                    Our Mission
                                </div>
                                <div className="txtNormal mar_20">
                                    At GaadiPlus.com, our mission is simple: to provide accessible and affordable financing options for two-wheeler enthusiasts across the country. We believe that everyone deserves the freedom of owning their own bike or scooter, and we're committed to helping you achieve that goal.
                                </div>
                                <div className="txtBoldBig mar_5">
                                    What Sets Us Apart
                                </div>
                                <div className="txtNormal mar_20">
                                    What sets GaadiPlus.com apart from the rest is our unique approach to financing. Unlike traditional lenders, we specialize in two-wheeler loans, allowing us to offer tailored solutions that meet the specific needs of our customers. But what truly makes us stand out is our exclusive additional discount offer.
                                </div>
                                <div className="txtBoldBig mar_5">
                                    Exclusive Additional Discount
                                </div>
                                <div className="txtNormal mar_20">
                                    When you choose to finance your two-wheeler purchase through GaadiPlus.com, you not only get competitive loan options but also unlock an additional discount of Rs. 5000 on top of the final negotiated price of your vehicle. This special offer is available only to customers who secure their loan through our partner lenders, making it easier and more affordable than ever to ride away on the bike of your dreams.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;