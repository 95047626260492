import moment from "moment";
let fn = {};

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const validEmailRegex = RegExp(
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

fn.IsNull = (e) => {
    if (e === undefined || e === null) return true;
    return false;
};

fn.IsNullValue = (e) => {
    if (e === undefined || e === null || e === "") return true;
    return false;
};


fn.ToString = (e, defa) => {
    if (fn.IsNull(e)) return defa ? defa : "";
    return e.toString();
};

fn.IsValidPhone = (e) => {
    if (fn.IsNullValue(e) || !phoneRegex.test(e)) {
        return false;
    }
    return true;
};

fn.IsEmailValid = (e) => {
    if (fn.IsNullValue(e) || !validEmailRegex.test(e)) return false;
    return true;
};

fn.ToUSAPhone = (e) => {
    if (fn.IsNullValue(e)) return null;
    if (phoneRegex.test(e)) {
        return e.replace(phoneRegex, "($1) $2-$3");
    }
    return null;
};

fn.IsArrayEmpty = (e) => {
    if (fn.IsNullValue(e)) return true;
    let tmp = e.filter((x) => !fn.IsNullValue(x));
    return tmp.length === 0;
};

fn.IsArrayNull = (e) => {
    if (fn.IsNull(e)) return true;
    if (e.length > 0) {
        return false;
    }
    return true;
};

fn.TimeStamp = () => { return new Date().valueOf(); }

fn.GetErrorBackColor = (errors, fld) => {
    let _indx = -1;
    if (errors && errors.length > 0) {
        _indx = errors.findIndex((x) => x.field === fld);
        if (_indx === -1) {
            _indx = errors.findIndex((x) => x.field?.endsWith(fld));
        }
    }
    return _indx > -1 ? "#b22929" : undefined;
};

fn.IsFormValid = (id) => {
    let errors = [], reqMsg, type;
    const parent = document.getElementById(id);
    if (parent) {
        let elms = parent.querySelectorAll('[data-req="true"]');
        elms.forEach(elm => {
            if (fn.IsNullValue(elm.value)) {
                type = elm.attributes['data-location']?.value;
                reqMsg = elm.attributes['data-req-msg']?.value || "Field should not be empty";
                errors.push({ field: elm.id, value: reqMsg, type });
            }
        });

        elms = parent.querySelectorAll('[data-validation="true"]');
        elms.forEach(elm => {
            if (!fn.IsNullValue(elm.value)) {
                reqMsg = elm.attributes['data-validation-msg']?.value || "Provide valid input";
                const dataType = elm.attributes['data-validation-type']?.value;
                type = elm.attributes['data-location']?.value;
                if (dataType === 'DATE') {
                    if (!fn.IsValidDate(elm.value)) {
                        errors.push({ field: elm.id, value: reqMsg, type });
                    }
                }
            }
        });

        elms = parent.querySelectorAll('[data-opts="true"]');
        elms.forEach(elm => {
            let cAnchor = elm.querySelector('a.current');
            type = elm.attributes['data-location']?.value;
            if (!cAnchor) {
                errors.push({
                    field: elm.attributes['data-id'].value.replace(/\./g, '_'),
                    value: elm.attributes['data-req-msg']?.value || "Field should not be empty",
                    type
                });
            }
        });

        elms = parent.querySelectorAll('[data-sel-req="true"]');
        elms.forEach(elm => {
            let _value = parseInt(elm.attributes['data-sel-idx']?.value || 1);
            type = elm.attributes['data-location']?.value;
            if (elm.selectedIndex < _value) {
                errors.push({
                    field: elm.attributes['data-sel-id'].value,
                    value: elm.attributes['data-sel-msg']?.value || "Field should not be empty",
                    type
                });
            }
        });

        elms = parent.querySelectorAll('[data-error="true"]');
        elms.forEach(elm => {
            type = elm.attributes['data-location']?.value;
            errors.push({ field: elm.id, value: elm.attributes['data-val-msg']?.value || "Field should not be empty", type });
        });

    }

    return { valid: errors.length === 0, errors: errors };
}

fn.ToFloat = (e, defa) => {
    const re = /^[0-9\b]+$/;
    if (!fn.IsNullValue(e)) {
        let tmp = parseInt(e.toString().replace(".", ""));
        if (re.test(tmp)) {
            return parseFloat(e);
        }
    }
    return !fn.IsNullValue(defa) ? defa : "";
};


fn.INRCurrencyFormat = (e, nodecimals, nocurrency) => {
    if (fn.IsNullValue(e)) return "";
    let formatter = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
    });
    if (nocurrency) {
        formatter = new Intl.NumberFormat("en-IN", {
            currency: "INR"
        });
    }
    let tmp = formatter.format(e);
    if (nodecimals) tmp = tmp.substring(0, tmp.indexOf("."));
    return tmp;
};

fn.ToBool = (e, defa) => {
    if (fn.IsNullValue(e)) return false;
    if (e === "true" || e === true) return true;
    if (e === "false" || e === false) return false;
};

fn.IsJSONEmpty = (e) => {
    if (fn.IsNull(e)) return true;
    for (var key in e) {
        if (Object.prototype.hasOwnProperty.call(e, key)) {
            return false;
        }
    }
    return true;
};

fn.IsArrayEmpty = (e) => {
    if (fn.IsNullValue(e)) return true;
    let tmp = e.filter((x) => !fn.IsNullValue(x));
    return tmp.length === 0;
};

fn.SortBy = (e, field) => {
    if (fn.IsArrayEmpty(e)) return e;
    return e.sort(function (a, b) {
        return a[field].localeCompare(b[field]);
    });
};

fn.AddSelectOption = (input, sortBy, sortField, dField) => {
    if (sortBy) input = fn.SortBy(input, sortField);
    input.splice(0, 0, { _id: 0, value: "", [dField]: "Select" });
};

fn.ToDateFormat = (e, format, utc) => {
    if (fn.IsNullValue(e)) return "";
    if (fn.IsNullValue(format)) return moment(new Date(e));
    if (utc) return moment(e).utc().format(format);
    return moment(new Date(e)).format(format);
};

export default fn;
