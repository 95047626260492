import React from "react";
import Helper from "shared/helper";

const Component = (props) => {
    const { id, name, value, placeholder, errors, required, type, location, maxLength,
        requiredMessage, onInputChanged, onStateChanged, readOnly, className } = props;

    const tReadOnly = readOnly || false;
    const tType = type || "text";

    const inputProps = {
        id, name, placeholder, autoComplete: Helper.TimeStamp(),
        value: Helper.ToString(value), readOnly: tReadOnly, maxLength, className
    };

    const OnInputChanged = (e) => {
        const { name, value } = e.target;
        onInputChanged && onInputChanged({ name, value });
        OnBlurChanged(e);
    };

    const OnBlurChanged = (e) => {
        const { name, value } = e.target;
        let clear = false;

        if (required && Helper.IsNullValue(value)) {
            onStateChanged && onStateChanged({ name, requiredMessage, clear });
        } else {
            clear = true;
            onStateChanged && onStateChanged({ name, requiredMessage, clear });
        }

    };

    const OnKeyUp = (e) => { OnBlurChanged(e) };

    if (tType === 'textarea') {
        return (
            <textarea {...inputProps}
                rows="4" cols="50"
                autoComplete={"off"}
                onChange={OnInputChanged}
                onBlur={OnBlurChanged}
                onKeyUp={OnKeyUp}
                data-req={required}
                data-req-msg={requiredMessage}
                data-location={location}
                style={{
                    borderColor: Helper.GetErrorBackColor(errors, id)
                }}
            />
        );
    }

    return (
        <input {...inputProps}
            type={tType}
            onChange={OnInputChanged}
            onBlur={OnBlurChanged}
            onKeyUp={OnKeyUp}
            data-req={required}
            data-req-msg={requiredMessage}
            data-location={location}
            style={{
                borderColor: Helper.GetErrorBackColor(errors, id)
            }}
        />
    );
};

export default Component;
