const FooterHome = () => {
    return (
        <div id="footer_home" style={{ display: "none" }}>
        </div>
    );
}

const FooterInPage = () => {
    return (
        <div id="footer">
            <div className="footer_blk">
                <div className="widthfix">
                    <div className="footerInpage">
                        Gaadiplus &copy; {new Date().getFullYear()} | All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    );
}

const Component = ({ type }) => {
    return type ? <FooterHome /> : <FooterInPage />
};

export default Component;