import { React, environment } from "shared/common";
import { NumberInput, SelectInput } from 'components';
import Helper from "shared/helper";
import { setObjectState, clearObjectState } from "features/objectState/objectStateSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import * as CommonApi from "services/CommonApi";
import Tooltip from "./Tooltip";
import RenderQutotaionForm from "./RenderQutotaionForm";
import { fetchVehicleManufacturers } from "features/enum/enumSlice";

const RenderQutotaionInfo = (props) => {

    const dispatch = useDispatch();
    const [initialize, setInitialize] = React.useState(false);
    const [showTooltip, setShowTooltip] = React.useState(false);
    const [tooltipStyle, setTooltipStyle] = React.useState({});
    const [error, setError] = React.useState(null);
    const [errors, setErrors] = React.useState([]);
    const [showWarning, setShowWarning] = React.useState(false);
    const [showReSendOTP, setShowReSendOTP] = React.useState(false);
    const { onGenerateQuoteClicked, isOpen, currentTime } = props;
    const myTimeout = React.useRef(null);

    const row = useSelector(state => state.objectStateReducer) || {};
    const { vehicleManufacturers } = useSelector(state => state.enumReducer);
    const MaxLoanAmount = Helper.IsNullValue(row?.onRoadPrice) ? 0 : parseFloat(row.onRoadPrice) * 0.95;


    const handleMouseEnter = (event) => {
        setTooltipStyle({
            top: event.clientY + 10 + 'px',
            left: event.clientX + 10 + 'px'
        });
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };


    const OnSubmitClicked = async (e) => {
        e.preventDefault();

        setError(null);
        setErrors([]);

        const { errors, valid } = Helper.IsFormValid('quoteInfo');

        if (!valid) {
            setErrors(errors);
            return;
        }

        global.Busy(true);
        let rslt = await CommonApi.ValidateOTP(null, row.mobileNumber, row.mobileOTP);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }

        let data = {
            currentTime: currentTime, brandName: row.brandName,
            onRoadPrice: row.onRoadPrice, downPayment: row.downPayment, mobileNumber: row.mobileNumber
        };

        global.Busy(true);
        await CommonApi.SendGaadiLoanQuote(data);
        global.Busy(false);

        setShowReSendOTP(false);
        dispatch(setObjectState({ otpSent: false }));
        clearTimeout(myTimeout.current);
        if (onGenerateQuoteClicked) onGenerateQuoteClicked(row);

    }

    const OnMaxValueExceeded = (e) => { setShowWarning(e); }

    const OnValueChanged = (e, isFloat) => {
        const { name, value } = e;
        dispatch(setObjectState({ [name]: isFloat ? Helper.ToFloat(value) : value }));
    }

    const OnStateChanged = (e) => {
        const { name, requiredMessage, clear } = e;
        let tmp = errors.filter((x) => x.field !== name) || [];
        if (!clear) tmp.push({ field: name, value: requiredMessage });
        setErrors(tmp);
        setShowWarning(false);
    }

    const OnGetOTPClicked = async (e) => {
        e.preventDefault();
        setError(null);
        setErrors([]);

        if (Helper.IsNullValue(row?.mobileNumber)) {
            setErrors([{ field: "mobileNumber", value: "Mobile Number is required" }]);
            return;
        }

        global.Busy(true);
        let rslt = await CommonApi.GetMobileOtp(row.mobileNumber, 56);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }
        dispatch(setObjectState({ otpSent: true }));
        setShowReSendOTP(false);
        myTimeout.current = setTimeout(() => {
            setShowReSendOTP(true);
            dispatch(setObjectState({ otpSent: false }));
        }, 30000)
    }

    if (initialize) {
        setInitialize(false);
        setErrors([]);
        setShowReSendOTP(false);
    }

    React.useEffect(() => {
        if (isOpen) {
            setInitialize(true);
        }
    }, [isOpen]);

    return (
        <>
            <form id="quoteInfo">
                <ul className='loanQuoteForm'>
                    <li className='loanQuoteForm'>
                        <div className='formContentHead'>Brand Name</div>
                        <div className='dealerSelectField'>

                            <SelectInput
                                title="Brand name"
                                id="brandName"
                                name="brandName"
                                value={Helper.ToString(row?.brandName)}
                                options={vehicleManufacturers}
                                displayId={"text"}
                                valueId={"value"}
                                onInputChanged={OnValueChanged}
                                onStateChanged={OnStateChanged}
                                errors={errors}
                                required={false}
                                placeholder={'Brand Name'}
                                validIndex={1}
                            />

                        </div>
                    </li>
                    <li className='loanQuoteForm'>
                        <div className='formContentHead'>OnRoad Price</div>
                        <div className="formMandatory">*</div>
                        <Link><div className="infoIcon" onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}></div></Link>
                        {showTooltip && <Tooltip text="The on-road price of the bike varies based on model and dealership" style={tooltipStyle} />}
                        <div className="loanQuoteTextField">
                            <div className="rupeeIcon"></div>
                            <NumberInput
                                maxLength={10}
                                id="onRoadPrice"
                                name="onRoadPrice"
                                placeholder={'On Road Price'}
                                readOnly={!Helper.IsNullValue(row?.downPayment) && parseInt(row.downPayment) > 0}
                                value={Helper.ToString(row?.onRoadPrice)}
                                onInputChanged={(e) => OnValueChanged(e, true)}
                                onStateChanged={OnStateChanged}
                                errors={errors}
                                required={true}
                                requiredMessage={'On Road Price is required'}
                                className="no-spinner"
                            />
                        </div>
                    </li>
                    <li className='loanQuoteForm'>
                        <div className='formContentHead'>Down Payment</div>
                        <div className="formMandatory">*</div>
                        <div className="loanQuoteTextField">
                            <div className="paymentIcon"></div>
                            <NumberInput
                                maxValue={MaxLoanAmount}
                                onMaxValueExceeded={OnMaxValueExceeded}
                                maxLength={10}
                                id="downPayment"
                                name="downPayment"
                                placeholder={'Down Payment'}
                                readOnly={Helper.IsNullValue(row?.onRoadPrice) || parseInt(row.onRoadPrice) === 0}
                                value={Helper.ToString(row?.downPayment)}
                                onInputChanged={(e) => OnValueChanged(e, true)}
                                onStateChanged={OnStateChanged}
                                errors={errors}
                                required={true}
                                requiredMessage={'Down Payment is required'}
                                className="no-spinner"
                            />
                        </div>
                    </li>
                    {!row?.isOTPValid && (
                        <>
                            <li className='loanQuoteForm'>
                                <div className="loanQuoteTextField_OTP">
                                    <NumberInput
                                        maxLength={10}
                                        id="mobileNumber"
                                        name="mobileNumber"
                                        placeholder={'Mobile No'}
                                        value={Helper.ToString(row?.mobileNumber)}
                                        onInputChanged={OnValueChanged}
                                        onStateChanged={OnStateChanged}
                                        errors={errors}
                                        required={true}
                                        requiredMessage={'Mobile No is required'}
                                        className="no-spinner"
                                    />

                                    <button className="btn_insideTxtField" onClick={OnGetOTPClicked}>Get OTP</button>
                                </div>
                            </li>
                            <li className='loanQuoteForm'>
                                <div className="loanQuoteTextField">
                                    <NumberInput
                                        maxLength={10}
                                        id="mobileOTP"
                                        name="mobileOTP"
                                        placeholder={'Enter OTP'}
                                        value={Helper.ToString(row?.mobileOTP)}
                                        onInputChanged={OnValueChanged}
                                        onStateChanged={OnStateChanged}
                                        errors={errors}
                                        required={true}
                                        requiredMessage={'OTP is required'}
                                        className="no-spinner"
                                    />
                                </div>
                            </li>
                            {!row.noLoanApproved && (
                                <li className='loanQuoteForm'>
                                    <input type='button' value="Generate Quote" className='Btn_GenerateScheme' onClick={OnSubmitClicked} />
                                </li>
                            )}
                        </>
                    )}
                </ul>
                {showReSendOTP && (
                    <div className="resendOTP_Blk">
                        <input type="button" className="resendOTP_button" value="Re-Send OTP" onClick={OnGetOTPClicked} />
                    </div>
                )}

                {error && <div className="errorMsg">{error}</div>}
                {row.noLoanApproved && <div className="errorMsg">You are not eligible for loan as the loan amount is less than 10,000</div>}
                {(row.downPaymentInValid || showWarning) && <div className="gaadiPlusErrorMsg">Down payment cannot exceed 95% of on-road price</div>}
                {row?.otpSent && <div className="successMsg">OTP sent successfully, please check your mobile</div>}
            </form>
        </>
    )
}

const Component = (props) => {

    const { isPopup, onClose, isOpen } = props;
    const dispatch = useDispatch();
    const row = useSelector(state => state.objectStateReducer) || {};
    const currentTime = Helper.ToDateFormat(new Date(), "DD/MM/YYYY HH:mm:ss");

    const OnGenerateQuoteClicked = (e) => {
        dispatch(setObjectState({ isOTPValid: true }));
    }

    const GetFormattedData = () => {
        const discountAmount = Math.floor(row.specialDiscount / row.tenure);
        return {
            environment: environment, currentTime: currentTime,
            onRoadPrice: Helper.INRCurrencyFormat(row.onRoadPrice, true), downPayment: Helper.INRCurrencyFormat(row.downPayment, true),
            processingFee: Helper.INRCurrencyFormat(row.processingFee, true), loanAmtBeforeDiscount: Helper.INRCurrencyFormat(row.loanAmtBeforeDiscount, true),
            totalPaymentBeforeDiscount: Helper.INRCurrencyFormat(row.totalPaymentBeforeDiscount, true), specialDiscount: Helper.INRCurrencyFormat(row.specialDiscount, true),
            totalPaymentAfterDiscount: Helper.INRCurrencyFormat(row.totalPaymentAfterDiscount, true), emiAmount: Helper.INRCurrencyFormat(row.emiAmount, true),
            tenure: row.tenure, interestRate: row.interestRate, discountAmount: Helper.INRCurrencyFormat(discountAmount, true),
            displayEmiAmount: Helper.INRCurrencyFormat(row.displayEmiAmount, true), stampDutyCharges: Helper.INRCurrencyFormat(row.stampDutyCharges, true),
        }
    }

    const DownLoadPdf = async (filePath) => {
        await fetch(filePath)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "GaadiPlus_LoanScheme.pdf";
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    const OnPrint = async (e) => {
        e.preventDefault();
        let data = GetFormattedData();

        global.Busy(true);
        let rslt = await CommonApi.GeneratePDF(data);
        global.Busy(false);
        if (rslt.status !== 100) {
            return;
        }
        let filePath = rslt.file;
        await DownLoadPdf(filePath);
    }

    const OnShare = async (e) => {
        e.preventDefault();
        let data = GetFormattedData();

        global.Busy(true);
        let rslt = await CommonApi.GeneratePDF(data);
        global.Busy(false);
        if (rslt.status !== 100) {
            return;
        }
        let filePath = rslt.file;

        const response = await fetch(filePath);
        const blob = await response.blob();
        const file = new File([blob], 'GaadiPlus_LoanScheme.pdf', { type: 'application/pdf' });

        if (navigator.canShare && navigator.canShare({ files: [file] }) && e.isTrusted) {
            navigator.share({
                files: [file],
                title: 'GaadiPlus_LoanScheme',
                text: 'GaadiPlus LoanScheme'
            }).catch(async (error) => {
                console.error('Error sharing PDF:', error);
                await DownLoadPdf(filePath);
            });
        } else {
            alert('Your browser does not support sharing files.');
        }
    }

    React.useEffect(() => {
        if (isOpen) {
            sessionStorage.removeItem('loadedCount');
            dispatch(clearObjectState());
            dispatch(fetchVehicleManufacturers());
        }
    }, [isOpen, dispatch]);

    return (
        <>
            {isPopup ? (
                <ul className='loanQuoteLogoHeadBtn'>
                    <li className='loanQuoteLogoHeadBtn'>
                        <div className='gaadiplusLogo logoPosLoanQuote'></div>
                    </li>
                    <li className='loanQuoteLogoHeadBtn'>Generate Quotation</li>
                    <li className='loanQuoteLogoHeadBtn'>
                        {row.isOTPValid && row.validInput && <button className="printIcon" title='Print' onClick={OnPrint}></button>}
                        {row.isOTPValid && row.validInput && <button className="shareIcon" title='Share' onClick={OnShare}></button>}
                        <button className="closeIcon" title='Close' onClick={onClose}></button>
                    </li>
                </ul>
            ) : (
                <ul className='loanQuoteHeadBtn'>
                    <li className='loanQuoteHeadBtn'>Generate Quotation</li>
                    <li className='loanQuoteHeadBtn'>
                        {row.isOTPValid && row.validInput && <button className="printIcon" title='Print' onClick={OnPrint}></button>}
                        {row.isOTPValid && row.validInput && <button className="shareIcon" title='Share' onClick={OnShare}></button>}
                    </li>
                </ul>
            )}

            <div className="loanQuoteStyle">
                <RenderQutotaionInfo onGenerateQuoteClicked={OnGenerateQuoteClicked} currentTime={currentTime} isOpen={isOpen} />
                {row.isOTPValid && <RenderQutotaionForm {...props} />}
            </div>
        </>
    );
};

export default Component;
