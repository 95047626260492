import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import kannadaTranslations from '../../content/translations/kn.json';
// import tamilTranslations from '../../content/translations/ta.json';
// import teluguTranslations from '../../content/translations/te.json';
// import malayalamTranslations from '../../content/translations/ml.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    hello: 'Hello',
                },
            },
            kn: {
                translation: kannadaTranslations,
            },
            // ta: {
            //     translation: tamilTranslations,
            // },
            // te: {
            //     translation: teluguTranslations,
            // },
            // ml: {
            //     translation: malayalamTranslations,
            // },
        },
        lng: 'en', // Default
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
